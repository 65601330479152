/*

Primary: #1a1a1e;
Secondary: #252528;
Accent: #4e4e4e;

Buttons / Links: #3D9CEF
Buttons / Links (Hover): #3186d1 

Destroy: #ae3838
Destroy (Hover): #8c2525

Modify: #bb610d
Modify (Hover): #88490e

*/

:root {
  --primary: #0d0e0e;
  --secondary: #242829;

  --secondary-hover: #393e41;

  --mail: #C53624;
  --discord: #5865F2;
  --bluesky: #0886FE;
  --github: #FFFFFF;
  --linkedin: #0077B5;

  --text-primary: #00B2D2;
  --text-secondary: #FFFFFF
}

html {
  /* This is because Chrome on Android makes the nav bar white despite the page background clearly
     not being white. Ideally it would match the background colour, but this is at least better
     than before. */
  color-scheme: dark;
}

@media (min-width:320px)  { 
  html {
    font-size: 150%;
  }
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
}
@media (min-width:600px)  {
  html {
    font-size: 150%;
  }
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
}
@media (min-width:801px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  html {
    font-size: 150%;
  }
}
@media (min-width:1025px) { 
  /* big landscape tablets, laptops, and desktops */ 
  html {
    font-size: 100%;
  }
}


@font-face {
  font-family: 'Product Sans Bold';
  src: url('./fonts/Product Sans Bold.ttf') format('truetype')
}

body {
  color: white;
  margin: 0;
  font-family: 'Product Sans Bold';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
